<template>
  <Chat />
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Chat from '@/components/Chat.vue'; // @ is an alias to /src

@Options({
  components: {
    Chat,
  },
})
export default class App extends Vue {}
</script>
<style lang="scss">
  @import "./styles/main.scss";
</style>
