import { isNotDefaultGuestName } from "@/lib/utils";

export class ApiUser {

    uid = "";
    displayName = "Guest";
    identifier = "guest-user";
    role = "visitor";
    valid = false;

    constructor(user: any = null) {
      if (user instanceof Object) {
        Object.entries(user).forEach(entry => {
          const [k, v] = entry;
          if (typeof v === "string") {
            switch (k) {
              case "uid":
              case "id":
                this.uid = v;
                break;
              case "identifier":
              case "email":
                this.identifier = v;
                  break;
              case "role":
                this.role = v;
                break;
              case "name":
              case "displayName":
              case "fullName":
                this.displayName = v;
                break;
            }
          }
          if (typeof v === "boolean" && k === "valid") {
            this.valid = v;
          }
          if (typeof v === "number" && k === "uid") {
            this.uid = v.toString();
          }
        })
      }
    }

    get hasDefaultGuestName() {
      return !isNotDefaultGuestName(this.displayName);
    }

    get hasUid() {
      return this.uid.length > 2;
    }

    get chatRole() {
      switch (this.role) {
        case 'artist':
        case 'student':
        case 'admin':
        case 'editor':
        case 'host':
          return 'host';
        default:
          return 'visitor';
      }
    }

    get isHost() {
      return this.chatRole === "host";
    }

    get isVisitor() {
      return this.chatRole !== "host";
    }

}