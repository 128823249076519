export const backend = {
    api: "https://studentupload-rca2021.platform-3.co.uk/api/",
    mediaPath: "https://studentupload-rca2021.platform-3.co.uk/media/",
    key: "eKabd7G;bd",
    suffixSplitChars: ["%", ".", ","],
    httpAuth: {
        user: "WiP2021RCA",
        pass: "PeatRem7TrP8",
    },
};

const talkAccountMode: string = "live";

const isTestMode = talkAccountMode === "test";

const talkAppId = isTestMode? 'tLu9fVNX' : 'MfSr4umc';

const talkSecret = isTestMode? 'sk_test_NJiNBhDhIFIO0hId7CycmMiRaXi8rMIC' : 'sk_live_clbMv6uK55JChJLeYaF8XHfDVwK5ALq8';

export const talkParams = {
    baseUrl: 'https://api.talkjs.com/v1',
    appId: talkAppId,
    secret: talkSecret,
}

// Element containing a data-id attribute. This must be student's UID (the short numeric code)
// e.g. <div data-id="1234"></div> where 1234 is student's uid as returned from the feed;
// This may be inside the #root element of an app that is rendered with the rest of the host page. For development it's the same as chat-widget container
export const chatIdElementId = 'chat-widget';

export const userQueryCacheSeconds = 120;
