


export const notEmptyString = (str: any = null, minLength = 0): boolean => {
if (typeof str === "string") {
    return str.trim().length > minLength;
}
return false;
};

export const isNotDefaultGuestName = (name: string, minLength = 1): boolean => {
  const cleanName = name.toLowerCase().trim();
  return cleanName !== "guest" && cleanName.length >= minLength;
}

export const validEmail = (email = ""): boolean => {
  return notEmptyString(email, 5)? /^[^@ ]+@[^@ ]+\.[^@ ]*?$/.test(email.trim()) : false;
}


export const isValidHostId = (hostId: any = null) => {
  return typeof hostId === "string" && ["1", "0"].includes(hostId) === false && hostId.length > 0
}