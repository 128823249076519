import {fromLocal }  from "./storage.service";
import { notEmptyString }  from "../lib/utils";
import { backend } from "../.config";
import { ApiUser } from "@/models/api-user";

declare global {
  interface Window {
    localStorage: any;
  }
}

export const getCurrentUser = () => {
  let result: any = { valid: false, uid: "", identifier: "", displayName: "" };
  const stored = fromLocal("current-user");
  if (stored.valid) {
    result = stored.data;
    result.uid = result.uid.toString();
  }
  return result;
}

export const getCurrentChatUser = (): ApiUser => {
  return new ApiUser(getCurrentUser());
}

export const generateVisitorId = () => {
  const ts = new Date().getTime();
  const randNum = Math.floor(Math.random() * 1000);
  return (ts % 100000).toString() + randNum.toString();
}

const randChar = (chars: string[]) => {
  const len = chars.length;
  const randIndex = Math.floor(Math.random() * len * 0.9999999);
  return chars[randIndex];
};

const randInt36 = (power = 3) => {
  const randInt = Math.floor(
    Math.random() * Math.pow(10, power) * 0.9999999999
  );
  return randInt.toString(36);
};

export const toDynamicKey = () => {
  const { key, suffixSplitChars } = backend;
  const ts = new Date().getTime();
  const tsList = ts
    .toString(36)
    .split("")
    .reverse();
  const offset = (parseInt(tsList[0], 36) % 6) + 1;
  const mergedList = tsList.map((ch, index) =>
    index === offset ? ch + key : ch
  );
  const baseStr = [mergedList.join(""), randInt36(3)].join(
    randChar(suffixSplitChars)
  );
  return btoa(baseStr);
};

const buildHeaders = (json = true) => {
  const { key, httpAuth } = backend;
  const headers: any = json?  {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  } : {};
  if (httpAuth) {
    if (notEmptyString(httpAuth.user, 3) && notEmptyString(httpAuth.pass, 3)) {
      const credentials = btoa(httpAuth.user + ":" + httpAuth.pass);
      headers.Authorization = "Basic " + credentials;
    }
  }
  if (key) {
    if (key.length > 1) {
      headers.token = toDynamicKey();
    }
  }
  return headers;
};

const postData = async (
    path: string,
    params:any = null,
  ) => {
    const headers = buildHeaders(true);
    const url = backend.api + path;
    let error:any = null;
    const response = await fetch(url, {
        method: 'POST',
        cache: 'no-cache',
        headers,
        body: JSON.stringify(params) 
      })
      .catch((err: any) => error = err);
    
    return response instanceof Response? response.json() : {error};
  };
  
  


export const authenticate = async (email: string, password: string) => {
    let user: any = {
      valid: false,
      uid: 0,
      slug: "",
    };
    const params = {
      email,
      password,
    };
    await postData("user/login", params).then((data) => {
      if (data instanceof Object) {
        user = data;
        if (user.identifier) {
          if (user.active) {
            user.valid = true;
          }
        }
        user.displayName = user.fullName;
        const {submission } = user;
        if (submission instanceof Object) {
          if (submission.displayName) {
            user.displayName = submission.displayName;
          }
          if (submission.slug) {
            user.slug = submission.slug;
          }
        }
        if (user.msg) {
          data.msg = user.msg;
        }
      }
    });
    return user;
  };
  