<template>
  <div class="chat-applet" :class="wrapperClasses">
    <fieldset id="login-container" class="pane user-login">
      <legend @click="handleLegend">
        <span class="text">{{loginLabel}}</span>
        <span class="right-trigger close" @click="close" title="Minimise">―</span>
      </legend>
      <div class="content">
        <p v-if="showIntro" class="introduction">{{introMsg}}</p>
        <p v-if="showUserName" class="form-row">
          <label for="username">Your name</label>
          <InputText id="host-username" type="text" v-model="visitorName" :placeholder="namePlaceholder" />
        </p>
        <p v-if="showLoggedinMsg" class="form-row loading message">
          {{studentLoggedInMsg}}
        </p>
        <p v-if="showEmail" class="form-row">
          <label for="user-email">email</label>
          <InputText id="user-email" type="text" v-model="email" />
        </p>
        <p v-if="showPassword" class="form-row">
          <label for="user-password">Password</label>
          <InputText id="user-password" type="password" v-model="password" />
        </p>
        <p v-if="showStatusMessage" class="form-row">
          <label for="status-message">{{statusMessageLabel}}</label>
          <Textarea id="status-message" type="text" v-model="statusMessage" cols="48" rows="2" />
        </p>
        <Button @click="signIn">{{submitLabel}}</Button>
        <p v-if="hasAuthMessage" class="error">{{authMessage}}</p>
        <Button v-if="mayLeaveChat" @click="leave">Leave Chat</Button>
        <p v-if="mayLeaveChat" class="form-row disable-chat-row">
          <label for="disable-chat">Disable chat on my page</label>
          <input type="checkbox" class="checkbox" id="disable-chat" @change="checkDisabled" :checked="isDisabled" />
        </p>
      </div>
    </fieldset>
    <div class="pane chat-container">
      <div class="pane-toggle">
        <span class="text">{{currentUserName}}</span>
        <span class="right-trigger edit" @click="switchToEditUser" :title="editHint">{{editLabel}}</span>
        <span class="right-trigger close" @click="close" title="Minimise">―</span>
      </div>
      <div id="chat-container" class="inner"></div>
    </div>
  </div>
  <div class="chat-trigger chat-trigger-main" :class="mainTriggerClasses" @click="toggleWidget">
    <span class="text" :title="visitorJoinHint">{{joinText}}</span>
  </div>
  <div class="chat-trigger chat-host-login" :class="hostTriggerOuterClasses"  @click="toggleHostLogin" :title="hostSignInText">
    [...]
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { TalkService } from '@/services/talk.service';
import { authenticate, generateVisitorId, getCurrentChatUser } from '../services/auth.service';
import { isNotDefaultGuestName, isValidHostId, notEmptyString, validEmail } from '../lib/utils';
import { ApiUser } from '@/models/api-user';
import { toLocal } from '@/services/storage.service';
import { chatIdElementId } from '@/.config';

export default class Chat extends Vue {
  
  showWidget = false;
  toggling = false;
  email = "";
  loginMode = "guest";
  password = "";
  visitorName = "";
  currentUser = new ApiUser(null);
  loggedIn = false;
  chatService: any = null;
  authMessage = "";
  activePane = 'login';
  modes = [
    {key: 'guest', name: 'Visitor' },
    {key: 'host', name: 'Host' }
  ];
  editCredentials = false;
  joinedAsGuest = false;
  statusMessage = "";
  availMessage = "";
  chatLoaded = false;
  hostOnline = false;
  initialised = false;
  isOnOwnPage = false;

  created() {
    this.detectContext();
  }

  mounted() {
    const container = document.getElementById('chat-container');
    if (container instanceof HTMLElement) {
      setTimeout(() => {
        this.initChat();
        this.addChangeObserver();
      }, 1000);
    }
  }

  addChangeObserver() {
    const observer = new MutationObserver((events) => {
      if (events instanceof Array && events.length > 0) {
        const record = events[0]
        if (record instanceof Object) {
          const {type, attributeName, target} = record;
          this.showWidget = false;
          if (type === "attributes" && attributeName === "data-id" && target instanceof HTMLElement) {
            const newHostId = target.getAttribute('data-id');
            if (notEmptyString(newHostId) && newHostId !== this.hostId && !this.isHost) {
              this.initialised = false;
              this.detectContext();
              setTimeout(() => {
                this.initChat(true);
              }, 250);
            }
            this.isOnOwnPage = newHostId === this.currentUser.uid;
          }
        }
      }
    });
    const idElement = document.getElementById(chatIdElementId);
    if (idElement instanceof HTMLElement) {
      const config = {
        attributes: true,
        childList: false,
        subtree: false,
      };
      observer.observe(idElement, config);
    }
  }

  getHostId() {
    const widget = document.getElementById(chatIdElementId);
    const idVal = widget instanceof HTMLElement? widget.getAttribute('data-id') : "";
    return typeof idVal === "string" ? idVal : "";
  }

  detectContext() {
    const hostIdAttr = this.getHostId();
    if (isValidHostId(hostIdAttr)) {
      this.chatService = new TalkService(hostIdAttr);
      setTimeout(() => {
        const user = getCurrentChatUser();
        if (user.valid) {
          if (user.isVisitor) {
            if (!user.hasDefaultGuestName) {
              this.visitorName = user.displayName;
            }
          }
          if (user.identifier.length > 6 && /@/.test(user.identifier)) {
            this.email = user.identifier;
          }
          this.currentUser = user;
        }
      }, 250);
    } else if (this.hasChatService) {
      this.chatService.reset();
    }
    this.isOnOwnPage = hostIdAttr === this.currentUser.uid;
  }

  initChat(reinit = false) {
    if (isValidHostId(this.hostId) || this.hasChatService) {
      this.currentUser = getCurrentChatUser();
      if (this.currentUser.valid) {
        if (this.currentUser.isHost && this.isOnOwnPage) {
            this.chatService.setHost(this.currentUser, reinit, reinit);
        } else {
          this.chatService.setVisitor(this.currentUser, reinit, reinit);
          if (this.currentUser.hasDefaultGuestName) {
            this.joinedAsGuest = true;
          }
        }
      } else {
        this.chatService.generateAnonVisitor();
      }
      if (!this.initialised) {
        this.initialised = this.chatService.isActive;
      } else {
        this.initialised = true;
        setTimeout(() => {
          this.initialised = false;
        }, 60 * 1000);
      }
      setTimeout(() => {
        if (this.chatService instanceof Object) {
          this.hostOnline = this.chatService.hostOnline;
          if (this.showWidget) {
            this.showWidget = this.chatService.hostHasVisited;
          }
          setTimeout(() => {
            this.hostOnline = this.chatService.hostOnline;
            if (this.showWidget) {
              this.showWidget = this.chatService.hostHasVisited;
            }
          }, 10000);
        }
      }, 3000);
      setInterval(() => {
        if (!this.chatLoaded) {
          this.chatLoaded = this.chatService.checkChatLoaded();
        }
        if (notEmptyString(this.chatService.availabilityText, 3) && this.chatService.availabilityText !== this.availMessage) {
          this.availMessage = this.chatService.availabilityText;
        }
      }, 1000);
    }
  }

  checkChat() {
    if (!this.initialised) {
      if (this.hasChatService && this.chatService.hostOnline && this.chatService.hasHost) {
        this.initialised = true;
      }
      setTimeout(()=>{
        if (this.hasChatService) {
          this.availMessage = this.chatService.availabilityText;
          if (this.chatService.isHost && this.activePane !== 'login' && notEmptyString(this.availMessage)) {
            this.statusMessage = this.availMessage;
          }
          this.hostOnline = this.chatService.hostOnline;
        }
      }, 1000);
      if (this.hasChatService) {
        if (this.chatService.hasInterlocutor === false) {
        this.initChat();
        } else {
          this.chatService.onlineStatus(this.hostId, this.isHost);
        }
      }
    }
  }

  toggleWidget() {
    const hostMode = this.isHost && this.hostOnline;
    this.authMessage = "";
    this.togggleWidgetDisplay(hostMode);
  }

  togggleWidgetDisplay(asHost = false) {
    if (!this.toggling) {
      this.toggling = true;
      const shown = this.showWidget === true;
      const forceHost = asHost && this.loginMode !== "host";
      const skip = asHost && !this.isHost && this.hasHost && !forceHost;
      if (!skip) {
        this.loginMode = asHost? "host" : "visitor";
        this.showWidget = !shown || forceHost;
      }
      if (asHost) {
        this.editCredentials = !this.isHost;
      }
      if (this.visitorHasDefaultName && asHost && !this.editCredentials) {
        this.editCredentials = !this.hostOnline;
        this.showWidget = !shown;
      }
      if (asHost && this.isVisitor) {
        this.chatService.removeGuest();
      }
      if (this.visitorHasDefaultName && !asHost) {
        this.editCredentials = true;
        this.activePane = 'login';
      }
      if (this.isLoggedIn && !shown) {
        this.showWidget = true;
        this.activePane = 'chat';
        this.editCredentials = false;
      }
      if (this.showWidget && this.isHost) {
        const statusMessage = notEmptyString(this.statusMessage, 3)? this.statusMessage : "";
        if (notEmptyString(statusMessage) && this.availMessage !== this.statusMessage) {
          this.chatService.sendStatusMsg('online', statusMessage, asHost);
        }
      }
      setTimeout(() => {
        this.toggling = false;
      }, 250);
    }
  }

  handleLegend() {
    if (this.isLoggedIn && this.isHost) {
      this.activePane = 'chat';
      this.editCredentials = false;
    }
  }

  toggleHostLogin() {
    this.authMessage = "";
    if (!this.showWidget) {
      this.togggleWidgetDisplay(true);
    } else {
      this.showWidget = false;
      this.loginMode = 'visitor';
    }
  }

  get showPassword() {
    return this.loginMode === "host" && !this.isLoggedIn;
  }

  get showUserName() {
    return this.loginMode !== "host";
  }

  get showEmail() {
    return this.loginMode !== "host" || !this.isLoggedIn;
  }

  get showStatusMessage() {
    return this.isHost || this.loginMode === "host";
  }

  get studentLoggedInMsg() {
    return `You are signed in as ${this.hostName}. Loading chat widget`;
  }

  get showLoggedinMsg() {
    return this.loggedIn && this.isHost && this.activePane === "chat";
  }

  get currentUserName() {
    return notEmptyString(this.visitorName)? this.visitorName : this.hasChatService? this.chatService.name : "";
  }

  get sameHost() {
    return this.hostId === this.currentUser.uid;
  }

  get hasHost() {
    return this.hasChatService ? this.chatService.hasHost : false;
  }

  get hostSignInText() {
    const availMsg = this.sameHost ? `Logged in as ${this.currentUser.displayName}` : this.hostOnline? "Student online" : "Student away";
    return this.hasHost? availMsg : "Sign in as a student";
  }

  get joinText() {
    return this.isHost && this.isLoggedIn? this.hostJoinText : this.visitorJoinText;
  }

  get hostJoinText() {
    return `Rejoin your chat`;
  }

  get hostName() {
    return this.hasChatService? this.chatService.hostName : "";
  }

  get visitorJoinText() {
    const name = this.hostName;
    return this.hostOnline ? `Chat with ${name}` : `Leave a message for ${name}`;
  }

  get hostHasVisited() {
    return this.hasChatService && this.chatService.hostHasVisited;
  }

  get visitorJoinHint() {
    const parts = this.visitorJoined? ["You're logged in"] : [];
    if (notEmptyString(this.statusMessage)) {
      parts.unshift(this.statusMessage);
    } else if (parts.length < 1) {
      const name = this.hostName;
      parts.push(`Chat with ${name}`);
    }
    return parts.join(", ");
  }

  get visitorJoined() {
    return this.hasChatService && this.chatService.hasVisitor && this.joinedAsGuest && !this.currentUser.hasDefaultGuestName;
  }

  get showVisitorLoginTrigger() {
    return !this.isHost;
  }

  get isDisabled() {
    return this.hasChatService && this.chatService.hostDisabled;
  }


  get isHost() {
    return this.hasChatService && this.chatService.isHost && this.chatService.hasHost;
  }

  get isVisitor() {
    return this.hasChatService && this.chatService.isVisitor;
  }

  get isLoggedIn() {
    return this.isHost || (this.hasChatService && this.isVisitor && this.chatService.hasVisitor && !this.currentUser.hasDefaultGuestName);
  }

  get headerIconClass() {
    return this.isHost ? 'pi-sign-out' : 'pi-user-edit';
  }

  get namePlaceholder() {
    return 'Enter a name';
  }

  get statusMessageLabel() {
    return "Availability text";
  }

  get editLabel() {
    return this.isHost ? "Leave" : "Back";
  }

  get editHint() {
    return this.isHost ? "Leave chat" : "Edit your details";
  }

  get mayLeaveChat() {
    return this.isLoggedIn;
  }

  get showIntro() {
    return this.hostOnline === false && this.loginMode !== "host";
  }

  get introMsg() {
    const name = this.hostName;
    const avText =  `${name} is away. Please leave a message`; 
    return this.hostOnline? "" : avText;
  }

  get offLineText() {
    return this.hostOnline && notEmptyString(this.availMessage, 3)? this.availMessage : "";
  }

  switchToEditUser() {
    this.statusMessage = "";
    this.editCredentials = true;
    this.activePane = 'login';
  }

  matchLoginModeLabel(key = "") {
    switch (key) {
      case "host":
        return this.isLoggedIn? "Rejoin the chat as a student" : "Log in as a student";
      default:
        return "Join the chat as a guest";
    }
  }

  get submitLabel() {
    return this.loginMode === "host"? this.isLoggedIn? "Update" : "Sign in" : this.visitorJoined ? "Edit details" : this.hostOnline? "Enter chat" : "Leave a message";
  }

  get hostId() {
    return this.hasChatService? this.chatService.hostId : "";
  }

  get hasChatService() {
    return this.chatService instanceof Object && this.chatService !== null;
  }

  get hasAuthMessage() {
    return this.authMessage.length > 2;
  }

  get hasSetVisitorName() {
    return this.hasChatService && !this.chatService.visitorNameIsGuest;
  }

  get wrapperClasses(): string[] {
    const toggleCls = this.showWidget? 'show-widget' : 'hide-widget';
    const cls: string[] = [toggleCls, this.studentLoginActiveClass];
    if (this.hasChatService && this.chatService.hasSession) {
      cls.push('has-chat-session');

      if (this.isHost) {
        cls.push('is-host');
      } else if (this.isVisitor && this.hasSetVisitorName) {
        cls.push('is-visitor');
      }
      
      this.activePane = this.editCredentials || !this.chatLoaded? 'login': 'chat';
      cls.push(['show', this.activePane].join('-'));
      if (this.hostOnline) {
        cls.push('host-online');
      }
      if (this.isLoggedIn) {
        cls.push('is-logged-in');
      }
      if (this.hasChatService) {
        if (this.chatService.hostActive) {
          cls.push('host-active');
        }
        if (this.chatService.hasActiveVisitor) {
          cls.push('active-visitor');
        }
        if (this.chatService.hostDisabled) {
          cls.push('disabled');
        }
      }
    }
    return cls;
  }

  get activeClass() {
    const hideIfHost = this.isHost && !this.isOnOwnPage;
    return this.hasChatService && this.notEmptyHost && this.chatService.hostHasVisited && !this.chatService.hostDisabled && !hideIfHost? 'active' : 'inactive';
  }

  get notEmptyHost() {
    return typeof this.hostId === "string" && this.hostId.length > 0 && ["0", "1"].includes(this.hostId) === false;
  }

  get studentLoginActiveClass() {
    return this.notEmptyHost? 'active' : 'inactive';
  }

  get mainTriggerClasses() {
    const cls = [this.activeClass];
    if (this.hasChatService) {
      if (this.chatService.showActivity) {
        cls.push('new-action');
      }
      if (this.showWidget) {
        cls.push('show-widget');
      }
    }
    return cls;
  }

   get hostTriggerOuterClasses() {
    const cls = [this.studentLoginActiveClass];
    const statusClass = this.hasChatService? this.chatService.hasHost? "online" : "away" : "offline";
    cls.push(statusClass);
    if (this.hasChatService) {
      if (this.chatService.showActivity) {
        cls.push('new-action');
      }
    }
    return cls;
  }

  get loginLabel() {
    return this.matchLoginModeLabel(this.loginMode);
  }

  get visitorHasDefaultName() {
    return !isNotDefaultGuestName(this.visitorName);
  }

  signIn() {
    this.authMessage = "";
    if (this.loginMode === "host") {
      if (this.isLoggedIn) {
        this.activePane = "chat";
        this.editCredentials = false;
        this.chatService.updateStatus(this.statusMessage);
        if (this.isHost) {
          if (notEmptyString(this.statusMessage)) {
            this.availMessage = this.statusMessage.trim();
            this.statusMessage = "";
          }
        }
      } else {
        this.login();
        this.statusMessage = "";
      }
    } else {
      this.saveGuest();
    }
  }

  validateGuest() {
    const msgs = [];
    const nameOK = notEmptyString(this.visitorName, 1) && isNotDefaultGuestName(this.visitorName);
    const emailOK = validEmail(this.email);
    if (!emailOK) {
      msgs.push("a name (other than Guest)");
    }
    if (!emailOK) {
      msgs.push("valid email");
    }
    if (msgs.length > 0) {
      const invalidStr = msgs.join(" and ");
      this.authMessage = `Please enter a ${invalidStr}`;
    }
    return nameOK && emailOK;
  }

  saveGuest() {
    if (this.validateGuest()) {
      this.visitorName = this.visitorName.trim();
      this.editCredentials = this.visitorHasDefaultName;
      this.activePane = this.editCredentials? 'login' : 'chat';
      if (!this.visitorHasDefaultName) {
        const visitorUid = this.currentUser.hasUid? this.currentUser.uid : generateVisitorId();
        const hasMessage = notEmptyString(this.statusMessage, 3);
        if (validEmail(this.email) && this.email !== this.currentUser.identifier) {
          this.chatService.matchUser(this.email).then((result: any) => {
            if (result instanceof Object && result.valid) {
              this.chatService.saveVisitor(this.visitorName, this.email, result.id, true, this.statusMessage);
            } else {
              this.chatService.saveVisitor(this.visitorName, this.email, visitorUid, true, this.statusMessage);
            }
          })
        } else {
          this.chatService.saveVisitor(this.visitorName, this.email, visitorUid, hasMessage, this.statusMessage);  
        }
        this.joinedAsGuest = true;
        this.statusMessage = "";
        return true;
      }
    }
    return false;
  }

  leave() {
    if (this.isHost) {
      const hostIdAttr = this.getHostId();
      this.chatService.logout(this.statusMessage, hostIdAttr);
      setTimeout(() => {
        this.statusMessage = "";
      }, 500);
    } else {
      this.chatService.removeGuest();
      this.currentUser = new ApiUser(null);
    }
  }

  login() {
    this.authMessage = "";
    authenticate(this.email, this.password).then(data => {
      if (data.valid && data.uid > 0) {
        const slug = data.slug;
        const uidStr = data.uid.toString();
        const matchesUid = uidStr === this.hostId;
        const matchesSlug = slug === this.hostId;
        if (!matchesUid) {
          data.uid = slug;
        }
        const studentMatched = matchesUid || matchesSlug;
        if (studentMatched) {
          this.loggedIn = true;
          this.currentUser = new ApiUser(data);
          if (this.currentUser.isHost) {
            this.loginMode = "host";
            this.editCredentials = false;
          }
          this.visitorName = data.displayName;
          const cKey = "current-user";
          this.hostOnline = this.chatService.hostOnline;
          toLocal(cKey, data, 86400);
          setTimeout(()=>{
            this.chatService.reinitAsHost(this.currentUser, this.statusMessage);
          }, 250);
        } else {
          this.authMessage = "You are not the host of this page";
        }
      } else {
        this.authMessage = "Email and/or password invalid";
      }
    });
  }

  close() {
    this.showWidget = false;
  }

  checkDisabled(e: any) {
    if (e instanceof Object && this.isHost) {
      const { checked } = e.target;
      this.chatService.disableUser(this.currentUser, checked === true);
      toLocal("disabled", checked);
    }
  }

}
</script>