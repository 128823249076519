import Talk from 'talkjs';
import jsSHA from 'jssha';
import { talkParams, userQueryCacheSeconds} from "@/.config";
import { ApiUser } from "@/models/api-user";
import { generateVisitorId, getCurrentChatUser, getCurrentUser } from './auth.service';
import { isNotDefaultGuestName, notEmptyString } from '../lib/utils';
import { clearUser, fromLocal, toLocal } from './storage.service';
// customize the following with your server and BOT account information

export class User {

    id = "";
    name = "";
    email? = "";
    photo? = "";
    welcomeMessage? = "";

    constructor(user: any = null) {
      if (user instanceof Object) {
        Object.entries(user).forEach(entry => {
          const [k, v] = entry;
          if (typeof v === "string") {
            switch (k) {
              case "id":
              case "name":
              case "email":
              case "photo":
              case "welcomeMessage":
                this[k] = v;
                break;
            }
          }
        })
      }
    }
}
export class TalkService {

  inbox: any = null;
  channel = 'showRCA2021';
  host: any = null;
  hostId = "";
  visitor: any = null;
  initialised = false;
  initialisedConversation = false;
  session: any;
  hostAvailable = false;
  hostHasVisited = false;
  numHostConversations = 0;
  otherUsers: Array<User> = [];
  showActivity = false;
  hasLoggedOut = false;
  availabilityText = "";
  hostName = "";
  hasOnlineStatus = false;
  hostOnline = false;
  convId = "";
  hostActive = false;
  hasActiveVisitor = false;
  waitForUpdates = false;
  hostDisabled = false;
  checking = false;

  constructor(hostId = "") {
    this.hostId = hostId.toString();
    this.waitForUpdates = false;
    Talk.ready.then(() => {
      this.initialised = true;
    });
  }

  setHost(apiUser: ApiUser, resetLogout = false, reinit = false) {
    if (resetLogout) {
      this.hasLoggedOut = false;
    }
    if (reinit) {
      this.hasLoggedOut = false;
    }
    if (!apiUser.hasDefaultGuestName && !this.hasLoggedOut) {
      this.setUser(apiUser, "host", reinit);
      const ts = resetLogout ? 1000 : 50;
      setTimeout(() => {
        this.onlineStatus(this.hostId, true);
      }, ts);
    }
    setTimeout(() => {
      this.hasActiveSessions(this.hostId).then(active => {
        this.hostActive = active;
      });
    }, 6000);
  }

  reinitAsHost(apiUser: ApiUser, message = "") {
    this.initialisedConversation = false;
    this.hasLoggedOut = false;
    this.setUser(apiUser, "host", true, message);
    setTimeout(() => {
      this.onlineStatus(this.hostId, true);
    }, 500);
    
  }

  setVisitor(apiUser: ApiUser, reinit = false, isNewHost = false) {
    if (!this.hasLoggedOut || reinit) {
      this.setUser(apiUser, "visitor", !this.isHost);
    }
    setTimeout(() => {
      this.onlineStatus(this.hostId, false, isNewHost);
    }, 500);
  }

  setUser(apiUser:ApiUser, role = "", reinit = false, message = "") {
    const { uid, displayName, identifier } = apiUser;
    if (uid) {
      const roleKey = apiUser.role === "host" && role === "visitor"? "host" : role;
      const chatUser = this.buildUser({
        id: uid,
        name: displayName,
        email: identifier
      }, roleKey, message);
      switch (role) {
        case "host":
          this.host = chatUser;
          break;
        case "visitor":
          this.visitor = chatUser;
          break;
      }
      if (!this.hasSession || reinit) {
        this.init(role);
      }
    }
  }

  init(role = "visitor") {
    if (this.initialised) {
      if (this.hasSession) {
        this.session.destroy();
      }
      this.convId = "";
      const me = role === "host"? this.host : this.visitor;
      if (me instanceof Object) {
        this.session =  new Talk.Session({
          appId: talkParams.appId,
          me,
          role,
          signature: this.generateSignature(me.id)
         });
        this.listen();
      }
    }
    setTimeout(() => {
      this.onlineStatus(this.hostId, role === "host");
      if (role === "host") {
        this.enableNotifications();
      }
    }, 3000);
  }

  generateSignature(idStr = "") {
    const sha = new jsSHA("SHA-256", "TEXT", {
      hmacKey: { value: talkParams.secret, format: "TEXT" },
    });
    sha.update(idStr);
    return sha.getHash("HEX");
  }

  listen() {
    this.session.on("message", (e: any) => {
      if (e instanceof Object) {
        const keys = Object.keys(e);
        if (keys.includes("body")) {
          this.showActivity = true;
          if (keys.includes("custom")) {
            if (Object.keys(e.custom).includes("status")) {
              this.availabilityText = e.body;
            }
          }
          if (keys.includes("conversation")) {
            this.convId = e.conversation.id;
          }
          setTimeout(() => {
            this.showActivity = false;
          }, 750);
        }
      }
    });
  }

  enableNotifications() {
    if (this.hasSession) {
      this.session.setDesktopNotificationEnabled(true, (data: any) => {
        if (data instanceof Object) {
          const keys = Object.keys(data);
          if (keys.includes("senderId")) {
            toLocal("interlocutor", data.senderId);
          }
        }
      })
    }
  }

  sendStatusMsg(status: string, msg = "", asHost = false) {
    const apiUser = getCurrentChatUser();
    this.saveUser(apiUser, msg, status);
  }

  generateAnonVisitor() {
    if (!this.hasLoggedOut) {
      const uid = generateVisitorId();
      const newUser = new ApiUser({uid, valid: true});
      this.setVisitor(newUser);
      toLocal('current-user', newUser);
    }
    return this.visitor;
  }

  updateVisitor(params: any = null) {
    const user = getCurrentUser();
    if (user.valid) {
      if (params instanceof Object && this.hasVisitor) {
        const { email, name } = params;
        Object.entries(params).forEach(entry => {
          const [k, v] = entry;
          if (typeof v === "string") {
            switch (k) {
              case "identifier":
              case "email":
                user.email = v;
                this.visitor.email = v;
                break;
              case "name":
              case "displayName":
                user.displayName = v;
                this.visitor.name = v;
                break;
            }
          }
        })
      }
    }
  }

  buildUser(user: User, role = "visitor", message = "") {
    if (this.initialised) {
      const email = notEmptyString(user.email, 6)? user.email : null;
      const inData: any = {
        id: user.id,
        name: user.name,
        email,
        welcomeMessage: user.welcomeMessage,
        role
      };
      if (notEmptyString(message)) {
        inData.availabilityText = message;
      }
      if (role === "host" && !this.hostDisabled) {
        
        if (user.id === this.hostId) {
          const skip = this.hasDisabled();
          if (!skip) {
            inData.custom = {
              status: "online"
            }
          }
        }
      }
      return new Talk.User(inData);
    }
  }


  get hasHost() {
    return this.host instanceof Object;
  }

  get hasActiveHost() {
    return this.hasHost && !this.hasLoggedOut;
  }

  get isActive() {
    return this.hasHost && this.otherUsers.length > 0;
  }

  get hasVisitor() {
    return this.visitor instanceof Object;
  }

  get visitorName() {
    return this.visitor instanceof Object ? this.visitor.name : "";
  }

  get name() {
    let name = "";
    if (this.hasSession) {
      if (this.isVisitor && this.hasVisitor) {
        name = this.visitorName;
      } else if (this.isHost && this.hasHost) {
        name = this.host.name;
      }
    }
    return name;
  }

  get visitorNameIsGuest() {
    return !isNotDefaultGuestName(this.visitorName);
  }

  startConversation(asHost = false) {
    const first = this.isVisitor ? this.visitor : this.host;
    const second = this.isVisitor ? this.host: this.visitor;
    if (first.id !== second.id) {
      const conversationId = Talk.oneOnOneId(first, second);
      const hasChanged = conversationId !== this.convId;
      this.initialisedConversation = true;
      const conversation = this.session.getOrCreateConversation(conversationId);
      conversation.setParticipant(first);
      conversation.setParticipant(second);
      this.inbox = this.session.createInbox({
        selected: conversation
      });
      this.convId = conversationId;
      if (this.inbox instanceof Object) {
        if (this.inbox.mount instanceof Function) {
          if (this.chatContainer instanceof HTMLElement) {
            if (hasChanged || !this.checkChatLoaded()) {
              this.inbox.mount(document.getElementById("chat-container"));
            }
          }
        }
      }
    }
  }

  hasDisabled() {
    const stored = fromLocal("disabled");
    let skip = false;
    if (stored.valid) {
      skip = stored.data === true;
    }
    return skip;
  }

  get chatContainer() {
    return document.getElementById("chat-container");
  }

  checkChatLoaded() {
    return this.chatContainer instanceof HTMLElement? this.chatContainer.childNodes.length > 0 : false;
  }

  reset() {
    this.hostId = "0";
    this.hostName = "";
    this.hasOnlineStatus = false;
    this.hostOnline = false;
    this.convId = "";
    this.hostAvailable = false;
    this.hostActive = false;
    this.hostHasVisited = false;
  }

  async fetchData(method = "users", refId = "", subMethod = "", mode = "GET", params: any = null, queryStr = "") {
    const parts = [talkParams.baseUrl, talkParams.appId, method, refId];
    if (notEmptyString(subMethod)) {
      parts.push(subMethod);
    }
    const url = parts.join("/");
    const hMap: Map<string, string> = new Map();
    hMap.set('Authorization', `Bearer ${talkParams.secret}`);
    if (['PUT','POST'].includes(mode)) {
      hMap.set('Content-Type', 'application/json');
    }
    let result: any = null;
    const headers = Object.fromEntries(hMap);
    const body = params instanceof Object && params !== null ? JSON.stringify(params) : null;
    const qStr = notEmptyString(queryStr) && queryStr.includes('=')? '?' + queryStr : "";
    await fetch(url + qStr, {
      method: mode,
      headers,
      body
    }).then(res => {
      return res.status === 200? res.json() : null;
    }).then(data => {
      if (data instanceof Array || data instanceof Object) {
        result = data;
      }
    });
    return result;
  }

  async fetchUserData(userId = "", subMethod = "", queryStr = "") {
    const key = ['users', userId, subMethod].join('_');
    const stored = fromLocal(key);
    if (stored.valid) {
      return stored.data;
    } else {
      const data = await this.fetchData('users', userId, subMethod, 'GET', null, queryStr);
      toLocal(key, data, userQueryCacheSeconds);
      return data;
    }
  }

  async fetchConversation(convId = "") {
    return await this.fetchData('conversations', convId);
  }

  async saveUser(user: ApiUser, avText = "", status = "online") {
    const hasAvText = notEmptyString(avText, 3)
    const statusText = hasAvText? avText : user.isHost? `${user.displayName} is online` : "";
    const sameHost = this.hostId === user.uid;
    const availabilityText = sameHost && hasAvText && avText.toLowerCase() !== "online" ? avText : "";
    //const welcomeMessage = user.isHost? "Hello, would you like to chat?" : avText;
    const asHost = user.isHost && user.uid === this.hostId;
    const inData:any = {
      name: user.displayName,
      email: [user.identifier],
      role: user.chatRole
    }
    if (user.isHost) {
      if (notEmptyString(availabilityText)) {
        inData.availabilityText = availabilityText;
      }
      inData.custom = {
        status
      }
    }
    await this.fetchData("users", user.uid, "", "PUT", inData);
    return user.hasUid;
  }

  async disableUser(user: ApiUser, isDisabled = true) {
    const key = isDisabled? "disabled" : "online";
    this.hostDisabled = isDisabled;
    return await this.saveUser(user, "", key);
  }

  async saveVisitor(name: string, email: string, uid: any = null, reinit = false, statusMsg = "") {
    if (!this.hasLoggedOut) {
      const user = new ApiUser({
        name,
        email,
        uid,
        valid: true
      });
      toLocal("current-user", user);
      await this.saveUser(user, statusMsg);
      setTimeout(() => {
        this.updateVisitor({name, email});
      }, 500);
      if (reinit) {
        this.setVisitor(user, true)
      }
    }
  }

  async fetchAllUsers() {
    const response = await this.fetchData("users");
    let items: any[] = [];
    if (response instanceof Object) {
      const {data} = response;
      if (data instanceof Array) {
        items = data;
      }
    }
    return items;
  }

  async matchUser(email = "") {
    const users = await this.fetchAllUsers();
    let user: any = { valid: false };
    if (users instanceof Array && users.length > 0) {
      const cleanedEmail = email.trim().toLocaleLowerCase();
      const matchedUser = users.find(u => {
        let valid = false;
        if (u instanceof Object) {
          if (Object.keys(u).includes("email")) {
            if (u.email instanceof Array) {
              valid = u.email.some((em: string) => {
                let matched = false;
                if (typeof em === "string") {
                  matched = em.trim().toLowerCase() === cleanedEmail;
                }
                return matched;
              })
            }
          }
        }
        return valid;
      });
      if (matchedUser instanceof Object) {
        user = {...matchedUser, valid: true};
      }
    }
    return user;
  }

  async hasActiveSessions(userId = "", checkConversations = false): Promise<boolean> {
    const results = await this.fetchUserData(userId, 'sessions');
    const active = results instanceof Array? results.filter(sess => !checkConversations || notEmptyString(sess.conversationId, 2)).length > 0 : false;
    return active;
  }

  async onlineStatus(userId = "", asHost = false, isNewHost = false) {
    const result = this.waitForUpdates ? null : await this.fetchUserData(userId);
    const hasUser = result instanceof Object && Object.keys(result).includes("name");
    this.hasOnlineStatus = false;
    if (hasUser && (!this.checkChatLoaded() || asHost || isNewHost) && !this.checking) {
      const { email, name, welcomeMessage, custom, availabilityText} = result;
      this.checking = true;
      this.hostName = name;
      await this.hasActiveSessions(this.hostId).then(active => {
        this.hostActive = active;
      });
      if (custom instanceof Object) {
        if (Object.keys(custom).includes("status")) {
          this.hasOnlineStatus = custom.status === "online" || isNewHost;
          this.hostDisabled = custom.status === "disabled";
        }
      }
      this.hostOnline = this.hasOnlineStatus && this.hostActive;
      if (notEmptyString(availabilityText)) {
        this.availabilityText = availabilityText;
      }
      if (email instanceof Array && email.length > 0 && notEmptyString(name)) {
        setTimeout(() => {
          this.checking = false;
        }, 500);
        this.hostHasVisited = true;
        if (this.isVisitor && !asHost) {
          this.hostAvailable = true;
          const firstEmail = email[0];
          const initMessage = notEmptyString(welcomeMessage)? welcomeMessage : "Hi, would you like to chat?";
          this.host = this.buildUser({
            id: this.hostId,
            name,
            email: firstEmail,
            welcomeMessage: initMessage
          }, "host");
          this.startConversation();
        } else {
          this.fetchHostConversionData(userId);
        }
      }
    }
    if (!hasUser) {
      this.waitForUpdates = true;
      setTimeout(() => {
        this.waitForUpdates = false;
      }, 60 * 60 * 1000);
    }
    setTimeout(() => {
      this.checking = false;
    }, 3000);
  }

 /*  async editEmma() {
    const inData = {
      "availabilityText":"Emma will next be available on Monday 28th June at 10am.",
      "custom":{"status":"online"},
      "email":["emma.harriet.wright@network.rca.ac.uk"],
      "name": "Emma Harriet Wright","role":"host"};
    await this.fetchData("users", "3162", "", "PUT", inData);
  } */

  async fetchHostConversionData(userId = "") {
    const convData = await this.fetchUserData(userId, 'conversations', 'limit=5');
    if (convData instanceof Object) {
      const { data } = convData;
      const hours12 = 12 * 60 * 60 * 1000;
      let numOtherUsers = 0;
      if (data instanceof Array && data.length > 0) {
        this.numHostConversations = data.length;
        this.otherUsers = [];
        const matchTs = (obj: any) => {
          let ts = 0;
          if (obj instanceof Object) {
            const { createdAt, lastMessage } = obj;
            ts = lastMessage instanceof Object? lastMessage.createdAt + hours12 : createdAt - hours12;
          }
          return ts;
        }
        data.sort((a,b) => matchTs(b) - matchTs(a));
        for (const row of data) {
          const cd = await this.fetchConversation(row.id);
          if (cd instanceof Object) {
            await this.processParticipants(cd, numOtherUsers);
            numOtherUsers++;
          }
        }
      }
    }
    this.startConversionWithGuest();
  }

  async processParticipants(cd: any = null, index = 0) {
    const { participants } = cd;
    const particpantIds = Object.keys(participants);
    for (const pid of particpantIds) { 
      if (pid !== this.hostId) {
        const otherUser = await this.fetchUserData(pid);
        if (otherUser instanceof Object) {
          const {id, name, email, welcomeMessage} = otherUser;
          const matchedMail = email instanceof Array? email.find(em =>/@/.test(em)) : null;
          const emailVal = notEmptyString(matchedMail, 5)? matchedMail : "";
          const rowUser = {
            id,
            name,
            email: emailVal,
            welcomeMessage
          };
          this.otherUsers.push(rowUser);
        }
      }
    }
  }

  startConversionWithGuest() {
    if (this.otherUsers.length > 0) {
      const matchedIndex = this.otherUsers.findIndex(u => isNotDefaultGuestName(u.name))
      const rowIndex = matchedIndex >= 0 ? matchedIndex : 0;
      const rowUser = this.otherUsers[rowIndex];
      if (!this.initialisedConversation && !this.hasLoggedOut) {
        setTimeout(() => {
          this.hasActiveSessions(rowUser.id, true).then(active => {
            this.hasActiveVisitor = active;
          });
        }, 6000);
        this.setVisitor(new ApiUser(rowUser));
        if (this.hasHost) {
          this.initialisedConversation = true;
          setTimeout(() => {
            this.startConversation(true);
          }, 250);
        }
      }
    }
  }

  getCurrentUid() {
    return getCurrentUser().uid;
  }

  get isVisitor() {
    return this.hasVisitor && this.getCurrentUid() !== this.hostId;
  }

  get isHost() {
    return this.hasHost && this.getCurrentUid() === this.hostId;
  }

  get hasSession() {
    return this.session instanceof Object;
  }

  get hasInterlocutor() {
    return this.isVisitor ? this.hasHost : this.hasVisitor;
  }

  removeGuest() {
    if (this.isVisitor) {
      clearUser();
      this.visitor = null;
    }
  }

  updateStatus(statusMsg = "", status = "online") {
    if (this.isHost) {
      const hostUser = getCurrentChatUser();
      this.saveUser(hostUser, statusMsg, status);
    }
  }

  logout(statusMsg = "", newHostId = "") {
    if (!this.hostDisabled) {
      this.updateStatus(statusMsg, status);
    }
    if (newHostId) {
      this.hostId = newHostId;
    }
    this.host = null;
    this.visitor = null;
    setTimeout(() => {
      clearUser();
    }, 250);
    setTimeout(() => {
      this.hasLoggedOut = true;
    }, 500);
  }
  
}
