import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from "primevue/config";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import Textarea from 'primevue/textarea';
const app = createApp(App);

import "primeicons/primeicons.css";

app.use(PrimeVue);
app.component("Button", Button);
app.component("InputText", InputText);
app.component("Textarea", Textarea);

app.mount('#chat-widget')
